// Here you can add other styles

*{
    font-family: 'Poppins', sans-serif;
}

.ag-theme-quartz {
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03)!important;
    --ag-font-size: 17px;
    --ag-font-family: $body-font-family !important;
}

.sidebar-nav{
    .nav-link{
        &.active{
            font-weight: bold;
        }
    }
}

.login{
    .left{
        border-top-right-radius: 77px;
        background-color: #ffefae;
        // background-image: linear-gradient(-45deg,#01c8c8,#14b980);
        user-select: none;

        .left-card{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 50px;

            .content{
                width: 600px;
                & h4{
                    font-weight: bold;
                    font-size: 26px;
                }

                & p{
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }

    .or-login{
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        &::after,&::before{
            content: '';
            flex: 1;
            display: inline-block;
            height: 1px;
            background-color: rgba(44, 56, 74, 0.681);
        }
    }
}

.not-allowed{
    cursor: not-allowed!important;
}