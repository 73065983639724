@media  (max-width:900px) {
    .left-reset-block,.right-reset-logo
    {
        display: none !important;
    }
    .right-reset-main-block
    {
        padding: 10px;
    }
    .right-reset-block
    {
        width: 100% !important;
        margin: 10px;
        position: relative;
        padding-top: 80px !important;
    }
    .reset-inside-logo
    {
        max-width: 50px !important; 
        margin-top: 5px;
    }
    .reset-inside-logo-block
    {
        background-color: #ffefae;
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    .right-main-login-block
    {
        position: relative
    }
}
.right-reset-block
{
    padding: 10px;
    /* padding-top: 45px; */
}
.reset-inside-logo-block
{
    display: none;
}
.right-reset-logo
{
    margin-bottom: 15px;
}